<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        v-if="isCreateButtonVisible"
        route-name="pollBlock_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-filter></app-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="list"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            :hasEditPermission="isEditButtonVisible"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import { mapState } from 'vuex'
import Preloader from '../../components/preloader/Preloader'
import Filter from '../../components/poll/PollBlockFilter'
import DataTable from '../../components/shared/DataTable'
import SiteService from '../../services/site/SiteService'
import ButtonCreate from '../../components/shared/ButtonCreate'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'PollBlockListView',
  data () {
    return {
      dataLoaded: false,
      dataTableConfig: {
        fields: {
          id: 'Id',
          title: 'Title',
          identifier: 'Identifier',
          site: 'Site',
          createdAt: 'Created at',
          createdBy: 'Created by'
        },
        actions: {
          detail: 'pollBlock_detail',
          edit: 'pollBlock_edit'
        },
        render: {
          site: function (data) {
            if (data) {
              return this.siteTitleById(data)
            }
          }.bind(this)
        }
      },
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_POLL_BLOCK_PERMISSIONS
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    isCreateButtonVisible () {
      return this.hasPermission(this.requiredPermissions.createButton)
    },
    isEditButtonVisible () {
      return this.hasPermission(this.requiredPermissions.editButton)
    },
    list () {
      return this.$store.getters['pollBlock/list']
    },
    totalCount () {
      return this.$store.getters['pollBlock/totalCount']
    },
    page () {
      return this.$store.getters['pollBlock/page']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appFilter: Filter,
    appDataTable: DataTable,
    appButtonCreate: ButtonCreate
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    getList () {
      this.$store.dispatch('pollBlock/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('pollBlock/setPage', page)
      this.getList()
    },
    siteTitleById (id) {
      return SiteService.getSiteTitle(id)
    }
  },
  mounted () {
    this.getList()
  }
}
</script>
