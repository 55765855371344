<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterList">
          <div class="row">
            <div class="col-lg-2">
              <app-select
                v-model="filter.site"
                :options="sites"
                label="Site"
                id="filter_site"
              >
              </app-select>
            </div>
            <div class="col-lg-3 col-md-6">
              <app-input v-model="filter.title" id="filter_title" label="Title"></app-input>
            </div>
            <div class="col-lg-3 col-md-6">
              <app-input v-model="filter.identifier" id="filter_identifier" label="Identifier"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.id" id="filter_id" label="Id"></app-input>
            </div>
            <div class="col-lg-2">
              <button
                type="button"
                class="btn btn-success pull-right m-t-30"
                data-test="search"
                @click="filterList"
              >
                Search
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import PollBlockFilter from '../../model/PollBlockFilter'

export default {
  name: 'PollBlockFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(PollBlockFilter)
    }
  },
  computed: {
    sites () {
      return this.enabledSites()
    }
  },
  components: {
    appInput: Input,
    appSelect: Select
  },
  methods: {
    filterList () {
      this.$store.commit('pollBlock/setPage', 1)
      this.$store.commit('pollBlock/setFilter', this.filter)
      this.$store.dispatch('pollBlock/fetch')
    },
    resetFilter () {
      this.$store.commit('pollBlock/setPage', 1)
      this.$store.commit('pollBlock/resetFilter')
      this.$store.dispatch('pollBlock/fetch')
    },
    enabledSites () {
      const sites = this.$store.getters['site/all']
      return sites.filter(site => site.enabled)
    }
  },
  created () {
    this.filter = this.$store.getters['pollBlock/filter']
  }
}
</script>
